var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.inputShow
        ? _c("img", {
            staticClass: "remarks-icon",
            attrs: {
              src: require("../../../assets/img/remarks-icon.png"),
              alt: "",
              srcset: "",
            },
            on: {
              click: function ($event) {
                _vm.inputShow = true
              },
            },
          })
        : _vm._e(),
      _vm.row.remark && !_vm.inputShow
        ? _c("span", { staticClass: "remarks-box" }, [
            _vm._v(_vm._s(_vm.row.remark)),
          ])
        : _vm._e(),
      _vm.inputShow
        ? _c("el-input", {
            attrs: { placeholder: "请输入备注", maxlength: "10" },
            on: { blur: _vm.handleSubmit },
            model: {
              value: _vm.remarksVal,
              callback: function ($$v) {
                _vm.remarksVal = $$v
              },
              expression: "remarksVal",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }