var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.signDialogVisible
    ? _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "标记无效电话",
                visible: _vm.signDialogVisible,
                width: "30%",
                center: "",
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.signDialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        rules: _vm.rule,
                        "label-width": "100px",
                        "label-suffix": ":",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("searchModule.plate_number") },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.successData.row.plateNumber)),
                          ]),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("searchModule.phone_number") },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.successData.phone))])]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "上传录音文件" } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upLoad",
                              staticClass: "avatar-uploader",
                              attrs: {
                                accept: ".mp3",
                                action:
                                  "/acb/2.0/manualOutboundCall/markInvalidPhone",
                                headers: _vm.uploadHeader,
                                "show-file-list": false,
                                "auto-upload": false,
                                "on-change": _vm.audioChange,
                                "file-list": _vm.fileList,
                                "http-request": _vm.uploadFile,
                              },
                            },
                            [
                              _vm.fileList.length > 0
                                ? _c("div", { staticClass: "avatar" }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.fileList[0].name) + " "
                                    ),
                                  ])
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon",
                                  }),
                            ]
                          ),
                          _vm._v(" 请上传MP3格式录音文件 "),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("searchModule.remarks"),
                            prop: "remarks",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.form.remarks,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "remarks", $$v)
                                },
                                expression: "form.remarks",
                              },
                            },
                            _vm._l(_vm.markList, function (item, i) {
                              return _c("el-option", {
                                key: i,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "close-btn",
                      on: { click: _vm.handleClose },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: { click: _vm.saveAddBlackFn },
                    },
                    [_vm._v("保 存")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }